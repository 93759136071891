"use client";

import React, { useRef, useEffect } from 'react';

import Container from '@/components/shared/Container';

const Slider = ({ children }) => {
    const scrollContainerRef = useRef(null);

	useEffect(() => {
		if (scrollContainerRef.current) {
			const container = scrollContainerRef.current.childNodes[0];

			const screenWidth = window.innerWidth;
			const containerWidth = container.offsetWidth;
			const containerScrollWidth = container.scrollWidth;
			
			let padding = screenWidth - (containerScrollWidth + containerWidth) + 30;
			// Convert to positive number (eg. -10 to 10)
			padding *= -1;

			const offset = container.offsetLeft;

			container.style.marginLeft = `${offset}px`;
			container.style.marginRight = `${padding}px`;
		}
	}, [scrollContainerRef]);

    return (
        <div ref={scrollContainerRef} className='overflow-x-auto no-scrollbar'>
            <Container className='related-grid py-4 relative'>
                {children}
            </Container>
        </div>
    );
}

export default Slider;