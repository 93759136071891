'use client';

import React from 'react'
import Container from '@/components/shared/Container'
import Typography from '@/components/shared/Typography'
import LinkButton from '@/components/shared/forms/LinkButton';
import Image from 'next/image'
import { getFileUrl, t } from '@/lib/helpers/directus'
import cx from "classnames";
import { route } from '@/lib/helpers/RouteHelper'
import Link from 'next/link'
import { replacePathsWithRoutes } from '@/lib/helpers/StringHelper'

export default function CardGridSection({ params, cards, backgroundPosition = 'right', className = '' }) {
    const content = (card) => {
        return (
            <>
                <div className='w-full h-full absolute inset-0'>
                    {card.header_media && (
                        <>
                            {card.header_media.type.includes('video') && (
                                <video alt={card.header_media.title ?? 'Hero video'} autoPlay loop muted playsInline className='w-full h-full object-cover'>
                                    <source src={getFileUrl(card.header_media.id)} type={card.header_media.type} />
                                </video>
                            )}
                            {card.header_media.type.includes('image') && (
                                <>
                                    <Image src={getFileUrl(card.header_media.id)} alt={card.header_media.title ?? 'Hero image'} width={1920} height={1080} className={`w-full h-full object-cover object-${backgroundPosition}`} />
                                </>
                            )}

                            {card.text_variant === 'light' && (
                                <div className='absolute inset-0 w-full h-full bg-black bg-opacity-30'></div>
                            )}
                        </>
                    )}
                </div>
                
                <div className='relative inset-0 w-full h-full z-10 px-6 py-10 flex flex-col gap-2'>
                    {card.translated_content ? (
                        <>
                            {card.translated_content.title && (
                                <Typography variant='h2' styleVariant='h3' className={card.text_variant === 'light' ? 'text-secondary-100' : ''}>
                                    {card.translated_content.title}
                                </Typography>
                            )}
                            {card.translated_content.subtitle && (
                                <Typography variant='h6' className={card.text_variant === 'light' ? 'text-secondary-100' : ''}>
                                    {card.translated_content.subtitle}
                                </Typography>
                            )}
                            {card.translated_content.text && (
                                <Typography variant='p' className={`${card.text_variant === 'dark' ? 'text-primary-800' : ''} w-full lg:w-2/3`}>
                                    {card.translated_content.text}
                                </Typography>
                            )}
                            {card.translated_content.short_description && (
                                <Typography variant='p' className={`${card.text_variant === 'dark' ? 'text-primary-800' : ''} w-full lg:w-2/3`}>
                                    {card.translated_content.short_description}
                                </Typography>
                            )}
                        </>
                    ) : (
                        <>
                            {card.title && (
                                <Typography variant='h2' styleVariant='h3' className={card.text_variant === 'light' ? 'text-secondary-100' : ''}>
                                    {card.title}
                                </Typography>
                            )}
                            {card.subtitle && (
                                <Typography variant='h6' className={card.text_variant === 'light' ? 'text-secondary-100' : ''}>
                                    {card.subtitle}
                                </Typography>
                            )}

                            {card.markdown ? (
                                <>
                                    <div className={`${card.text_variant === 'dark' ? 'text-primary-800' : ''} w-2/3`} dangerouslySetInnerHTML={{ __html: replacePathsWithRoutes(card.markdown, params) }}></div>
                                </>
                            ) : (
                                <>
                                    {card.text && (
                                        <Typography variant='p' className={`${card.text_variant === 'dark' ? 'text-primary-800' : ''} w-2/3`}>
                                            {card.text}
                                        </Typography>
                                    )}
                                </>
                            )}    
                        </>
                    )}

                    {(card.link || card.slug) ? (
                        <LinkButton href={route(params, card.link ? card.link : card.slug ?? '')} variant={card.text_variant === 'light' ? 'secondary-bordered' : 'primary-bordered'} wrapperClassName='mt-auto'>
                            {t(params, 'read_more')}
                        </LinkButton>
                    ) : (
                        null
                    )}
                </div>
            </>
        )
    }
    
    return (
		<Container className={cx('pb-20 md:px-10 lg:px-20 -mt-28 lg:-mt-32 relative z-10 text-white', className)}>
			<div className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
                {cards.map((card, index) => {
                    return (
                        <div key={index} style={{
                            height: '450px',
                            overflowY: 'auto',
                        }} className={`h-min bg-secondary-100 bg-opacity-80 flex flex-col justify-center items-center rounded-4xl overflow-hidden relative ${(index % 2 === 0 && index === cards.length - 1) ? 'lg:col-start-2' : ''}`}>
                            {(card.link || card.slug) ? (
                                <div className='block w-full h-full cursor-pointer' onClick={() => window.location.href = route(params, card.link ? card.link : card.slug ?? '')}>
                                    {content(card)}
                                </div>
                            ) : (
                                content(card)
                            )}
                        </div>
					)
				})}
			</div>
		</Container>

	)
}
