'use client'

import { getAllCollections, getCollectionByName } from "@/lib/helpers/directus";
import { replacePathsWithRoutes } from "@/lib/helpers/StringHelper";
import { useEffect } from "react"

export default function TestComponent({ params, pageData }) {
    useEffect(() => {
        console.log('Testcomponent loaded... params: ', params, `\n`, 'pageData: ', pageData);
    }, [])
    
    return (
        <>
            <div></div>
        </>
    )
}
