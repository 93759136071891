'use client'

import React from 'react';
import { ElfsightWidget } from 'next-elfsight-widget';
import Container from '@/components/shared/Container';

export default function ReviewSection() {
	return (
		<Container className='h-fit pb-20'>
			<ElfsightWidget widgetId="3d5ed259-f0ea-4432-ae7d-29043320358c" />
		</Container>
	)
}
