import(/* webpackMode: "eager" */ "/home/cleavr/ney.site-check.nl/releases/20241111110829141/app/[locale]/globals.css");
;
import(/* webpackMode: "eager" */ "/home/cleavr/ney.site-check.nl/releases/20241111110829141/components/shared/CardGridSection.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/cleavr/ney.site-check.nl/releases/20241111110829141/components/shared/FAQHeading.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/cleavr/ney.site-check.nl/releases/20241111110829141/components/shared/forms/Button.jsx");
;
import(/* webpackMode: "eager" */ "/home/cleavr/ney.site-check.nl/releases/20241111110829141/components/shared/forms/Form.jsx");
;
import(/* webpackMode: "eager" */ "/home/cleavr/ney.site-check.nl/releases/20241111110829141/components/shared/forms/LinkButton.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/cleavr/ney.site-check.nl/releases/20241111110829141/components/shared/HeaderV3.jsx");
;
import(/* webpackMode: "eager" */ "/home/cleavr/ney.site-check.nl/releases/20241111110829141/components/shared/ReviewSection.jsx");
;
import(/* webpackMode: "eager" */ "/home/cleavr/ney.site-check.nl/releases/20241111110829141/components/shared/Slider.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/cleavr/ney.site-check.nl/releases/20241111110829141/components/shared/TestComponent.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["BugsnagProvider"] */ "/home/cleavr/ney.site-check.nl/releases/20241111110829141/lib/context/bugsnag-context.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/cleavr/ney.site-check.nl/releases/20241111110829141/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/home/cleavr/ney.site-check.nl/releases/20241111110829141/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/cleavr/ney.site-check.nl/releases/20241111110829141/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/cleavr/ney.site-check.nl/releases/20241111110829141/node_modules/next/font/google/target.css?{\"path\":\"app/[locale]/fonts/index.js\",\"import\":\"Work_Sans\",\"arguments\":[{\"variable\":\"--font-work-sans\",\"weight\":[\"100\",\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"],\"style\":[\"normal\",\"italic\"],\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"workSans\"}");
