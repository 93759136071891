import { route } from "@/lib/helpers/RouteHelper";

export const replacePathsWithRoutes = (text, params) => {
    const pattern = /href="(.*?)"/g;
    try {
        return text.replace(pattern, (match, path) => {
            if(path.startsWith('/')) {
                return match.replace(path, route(params, path));
            } else {
                return match;
            }
        });
    } catch (error) {
        return text;
    }
};