import React from 'react';

import Typography from '@/components/shared/Typography';
import FilledHexagonIcon from '@/components/shared/icons/FilledHexagonIcon';

const Collapsable = ({ index, active, setActive, title, text }) => {
    const handleClick = () => {
        if (index === active) {
            setActive(null);
        } else {
            setActive(index);
        }
    };

    return (
        <div className="w-full bg-dark-transparent rounded-xl px-8 py-4 flex justify-between gap-5 cursor-pointer" onClick={handleClick}>
            <div>
                {title && (
                    <Typography variant='h3' styleVariant='h5' className='text-secondary-100'>
                        {title}
                    </Typography>
                )}

                {text && (
                    <Typography variant='p' className={`${active === index ? 'max-h-full scale-y-100' : 'max-h-0 scale-y-0'} transition-all duration-300 origin-top text-balance mt-2`}>
                        {text}
                    </Typography>
                )}
            </div>

            <div>
                {active === index ? (
                    <FilledHexagonIcon iconName='ChevronUp' iconClassName="" iconSize={18} className='w-6 h-6 mt-2' />
                ) : (
                    <FilledHexagonIcon iconName='ChevronDown' iconClassName="" iconSize={18} className='w-6 h-6 mt-2' />
                )}
            </div>
        </div>
    );
}

export default Collapsable